<template>
  <a-layout class="layout">
    <a-layout-header class="header">
      <div class="header1">
        <div class="content0"></div>
        <div class="content1 white fjsb fac">
          <span></span>
          <a-space class="hoder">
            <!-- <span>包头市资产交易平台欢迎您!</span> -->
            <span @click="searchShow = !searchShow" style="cursor: pointer">
              <a-icon type="search"
            /></span>
            <span v-if="searchShow">
              <div class="global-search-wrapper" style="width: 300px">
                <a-auto-complete
                  class="global-search"
                  size="small"
                  style="width: 100%"
                  placeholder="请输入资产名称"
                  option-label-prop="title"
                  @select="handleSelect"
                  @search="handleSearch"
                >
                  <template slot="dataSource">
                    <a-select-option
                      v-for="item in dataSource"
                      :key="item.assetNumber"
                      :title="item.assetName"
                    >
                      {{ item.assetName }}
                    </a-select-option>
                  </template>
                  <a-input>
                    <a-button
                      slot="suffix"
                      style="margin-right: -12px"
                      class="search-btn"
                      size="small"
                      @click="handleDetails()"
                      type="primary"
                    >
                      <a-icon type="search" />
                    </a-button>
                  </a-input>
                </a-auto-complete>
              </div>
            </span>
            <span>{{ now }}</span>
            <span>|</span>
            <span
              class="cp"
              v-if="!$store.state.user.token"
              @click="handleLogin"
              >注册</span
            >
            <span class="cp" v-if="$store.state.user.token">欢迎</span>
            <span>|</span>
            <span
              class="cp"
              v-if="!$store.state.user.token"
              @click="handleLogin"
              >登录</span
            >
            <span
              class="cp"
              v-if="$store.state.user.token"
              @click="handlePerson"
              >{{ JSON.parse($store.state.user.userInfo).realname }}</span
            >
            <span v-if="$store.state.user.token">|</span>
            <span class="cp" v-if="$store.state.user.token" @click="LoginOut"
              >退出</span
            >
          </a-space>
        </div>
      </div>
      <div class="header2">
        <div class="content2 fjsb fac">
          <div class="left">
            <img src="../assets/img/long-company-logo1.png" width="100%" />
          </div>
          <div class="right f fje h100">
            <div
              class="nav-item fac h100"
              :key="index"
              v-for="(item, index) in pathArray"
              :class="{ active: item.path === $route.path }"
            >
              <router-link
                :to="item.path"
                v-if="item.path == '/personal' && $store.state.user.token"
              >
                {{ item.name }}
              </router-link>
              <router-link v-if="item.path !== '/personal'" :to="item.path">
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </a-layout-header>
    <a-layout-content class="main" id="scrollableElement">
      <router-view style="min-height: 900px"></router-view>
      <div class="page-bottom">
        <div class="page-bottom-box">
          <div class="left">
            <div class="title">
              <img src="@/assets/img/long-logo.png" alt="" /><span
                >陇财盈商交易平台</span
              >
            </div>
            <div>
              <span>客服专线：<span class="phone">0931-8899466</span></span>
              <!-- <span style="margin-left: 20px">客服邮箱：btzxzh@126.com</span> -->
            </div>
            <div>
              <span
                >地址：甘肃省兰州市城关区东岗西路街道东岗西路638号文化大厦17层1717室</span
              >
            </div>
          </div>
          <div class="right">
            <div class="block_7 flex-col">
              <span class="text_163">竞买人帮助</span>
              <div class="group_12 flex-row justify-between">
                <div class="section_10 flex-col"></div>
                <span
                  class="text_164"
                  @click="handleMore('/help', '资产交易流程规范')"
                  >交易流程</span
                >
              </div>
              <div class="group_13 flex-row justify-between">
                <div class="box_43 flex-col"></div>
                <span
                  class="text_165"
                  @click="handleMore('/help', '出价竞拍')"
                  协议成交方式说明
                  >出价规则</span
                >
              </div>
              <div class="group_14 flex-row justify-between">
                <div class="block_8 flex-col"></div>
                <span
                  class="text_166"
                  @click="handleMore('/help', '协议成交方式说明')"
                  >成交规则</span
                >
              </div>
            </div>
            <div class="block_9 flex-col">
              <span class="text_167">支付帮助</span>
              <div class="group_15 flex-row justify-between">
                <div class="group_16 flex-col"></div>
                <span class="text_168" @click="handleMore('/help', '交保证金')"
                  >交保证金</span
                >
              </div>
              <div class="group_17 flex-row justify-between">
                <div class="group_18 flex-col"></div>
                <span class="text_169" @click="handleMore('/help', '竞拍成功')"
                  >支付尾款</span
                >
              </div>
              <div class="group_19 flex-row justify-between">
                <div class="group_20 flex-col"></div>
                <span
                  class="text_170"
                  @click="handleMore('/help', '平台收费规则')"
                  >收费规则</span
                >
              </div>
            </div>
            <div class="block_10 flex-col">
              <span class="text_171">常见问题</span>
              <div class="section_11 flex-row justify-between">
                <div class="box_44 flex-col"></div>
                <span class="text_172" @click="handleMore('/help', '办理交割')"
                  >办理过户</span
                >
              </div>
              <div class="section_12 flex-row justify-between">
                <div class="box_45 flex-col"></div>
                <span
                  class="text_173"
                  @click="handleMore('/help', '网络竞价方式说明')"
                  >操作流程</span
                >
              </div>
              <div class="section_13 flex-row justify-between">
                <div class="group_21 flex-col"></div>
                <span
                  class="text_174"
                  @click="handleMore('/help', '资产交易委托书')"
                  >资料下载</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="background-color: #3d3d3d">
        <span class="text_176">
          ·&nbsp;Copyright&nbsp;©&nbsp;2024&nbsp;甘肃省陇财资产运营有限公司&nbsp;All&nbsp;Rights&nbsp;Reserved·&nbsp;工信部备案号：<a
            href="https://beian.miit.gov.cn/"
            >陇ICP备2024013932号</a
          >
          ·&nbsp;公安备案号：
          <a href="https://beian.mps.gov.cn/#/query/webSearch"
            >甘公网安备62010202004611号</a
          >今日访问量：26·&nbsp;总访问量：126516
        </span>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import moment from "moment";
import { constantRoutes } from "@/router";

export default {
  name: "Layout.vue",
  components: {},
  data() {
    return {
      now: "",
      pathArray: [],
      dataSource: [],
      queryFrom: {},
      searchShow: false,
    };
  },
  created() {
    this.now = moment(new Date()).format("YYYY年MM月DD日 HH:mm:ss");
    setInterval(() => {
      this.now = moment(new Date()).format("YYYY年MM月DD日 HH:mm:ss");
    }, 1000);

    this.pathArray = constantRoutes[0].children.filter(
      (item) => item.meta.showInNav
    );
    // console.log(this.pathArray);
  },
  watch: {
    $route(to, from) {
      // 路由变化时，滚动到指定元素的顶部

      var scrollableElement = document.getElementById("scrollableElement");
      if (scrollableElement) {
        scrollableElement.scrollTop = 0;
      }
    },
  },
  methods: {
    handleDetails() {
      this.$router.push({
        path: "/assetDetail",
        query: {
          assetNumber: this.queryFrom.assetNumber,
          anouncementId: this.queryFrom.mainId,
        },
      });
    },
    handleSelect(value, a, b) {
      this.dataSource.forEach((item) => {
        if (item.assetNumber == value) {
          this.queryFrom = item;
        }
      });
    },
    handleSearch(value) {
      console.log(value);
      this.$services.news
        .searchList({
          searchName: value,
        })
        .then((res) => {
          this.dataSource = res.result;
        });
    },
    handlePerson() {
      this.$router.push({
        path: "/personal",
        query: { key: "个人中心" },
      });
    },
    LoginOut() {
      this.$store.dispatch("logout");
    },
    handleLogin() {
      this.$store.dispatch("isLogin");
    },
    handleMore(path, key) {
      router.push({ path: path, query: { key: key } });
    },
  },
};
</script>
<style lang="less" scoped>
.page-bottom {
  background-color: #f4f4f4;
  .page-bottom-box {
    margin: 0 auto;
    width: 1200px;
    height: 230px;
    display: flex;
    flex-direction: row;
    .left {
      width: 600px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .phone {
        font-size: 17px;
        color: #000;
        font-weight: 500;
      }
      .title {
        height: 50px;
        margin-top: 50px;
        display: flex;
        align-content: center;
        img {
          width: 35px;
          height: 35px;
        }
        span {
          color: #000;
          font-size: 25px;
          font-weight: 700;
          margin-left: 10px;
        }
      }
    }
    .right {
      width: 600px;
      display: flex;
      color: #000 !important;
    }
  }
}
.hoder {
  background-color: #525252;
  padding: 10px 20px 10px 60px;
  border-bottom-left-radius: 80px;
}
.layout {
  --header-height: 95px;
  --main-height: calc(100vh - var(--header-height));
}
.header {
  height: var(--header-height);
  padding: 0;
  line-height: normal;
  background-color: #fff;
}
.header1 {
  height: 25px;
  background-color: #fff;
}
.header-top {
  height: 5px;
  background-color: #525252;
}
.content0 {
  height: 5px;
  background-color: #525252;
}
.content1 {
  height: 25px;
  position: fixed;
  top: 5px;
  right: 0;
  /* background-color: #252626; */
  background-color: #fff;
}
.header2 {
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
.content2 {
  height: 60px;
  background-color: #ffffff;
}
.content1,
.content2 {
  width: 1150px;
  margin: 0 auto;
}
.content2 .left {
  margin-top: -20px;
  width: 300px;
}
.content2 .right {
  width: 850px;
}
.nav-item {
  padding: 0 20px;
}
.nav-item a {
  color: #282828;
}
.active a {
  /* background-color: var(--primary-color);
  color: var(--font-primary-color); */
  color: var(--primary-color);
  font-weight: bold;
  height: 30px;
  margin-top: 10px;
  border-bottom: 2px solid var(--primary-color);
}
.main {
  height: var(--main-height);
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
}
</style>
